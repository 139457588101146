// https://stackoverflow.com/a/71542987
export function disposeObserver(ro, ref) {
    if (ro == null)
        return;
    if (ref != null) {
        ro.unobserve(ref);
    }
    else {
        ro.disconnect();
    }
}
let domParser;
export function parseHtml(html) {
    if (domParser == null)
        domParser = new DOMParser();
    return domParser.parseFromString(html, 'text/html');
}
